namespace eh {

  export type OverlayContentType = 'video' | 'iframe' | 'iframe-fullw' | 'iframe-scaled' | 'local' | 'local-transp' | 'local-center' | 'raw' | 'local-s2' | 'local-s2w' | 'local-tw';

  export class Overlay implements IMessageHandler {
    public static readonly EventIdPreClose = 'ehOverlay:preClose';
    private static readonly DATA_KEY__LOCAL_REF_ID = 'localRefId';
    private static readonly DATA_KEY__OVERLAY_CONTENT = 'eh.Overlay.content';
  
    private static readonly logger = log.getLogger('eh.Overlay');
    private static instance: Overlay;
    
    public readonly id: string = ('eh-overlay-instance');
    private isMobileSafari: boolean;
    // white bg
    private layer: JQuery<HTMLElement>;
    private layerContent: JQuery<HTMLElement>;
    private layerTitle: JQuery<HTMLElement>;
    private layerClose: JQuery<HTMLElement>;
    private contentCloser: JQuery<HTMLElement> | undefined;
    // transparent bg
    private layerTransp: JQuery<HTMLElement>;
    private layerContentTransp: JQuery<HTMLElement>;
    private layerTitleTransp: JQuery<HTMLElement>;
    private layerCloseTransp: JQuery<HTMLElement>;
    private contentCloserTransp: JQuery<HTMLElement> | undefined;
    // tw
    private layerTw: JQuery<HTMLElement>;
    private layerContentTw: JQuery<HTMLElement>;
    private layerTitleTw: JQuery<HTMLElement>;
    private layerCloseTw: JQuery<HTMLElement>;
    private contentCloserTw: JQuery<HTMLElement> | undefined;

    static init($base: JQuery<HTMLElement>, isSnippet: boolean): void {
      if(!isSnippet) {
        Overlay.instance = new Overlay($base);
        eh.MessageNexus.setListener(window, Overlay.instance);
      } else {
        this.registerLinks($base);
      }
    }

    static registerLinks($base: JQuery<HTMLElement>): void {
        Overlay.instance?.registerOverlayLinks($base);
    }

    constructor(private readonly $base: JQuery<HTMLElement>) {
      const userAgent = window.navigator.userAgent;
      this.isMobileSafari = (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) !== null;
      this.registerOverlayLinks(this.$base);
      this.layer = $('.eh-marker-overlay-opaque');
      if (this.isMobileSafari) {
        this.layer.addClass('safari-mobile')
      }
      this.layerContent = this.layer.find('.eh-overlay--content-body');
      this.layerClose = this.layer.find('.eh-overlay--close');
      this.layerTitle = this.layer.find('.eh-overlay--headline');
 
      this.layerTransp = $('.eh-marker-overlay-transp');
      if(this.isMobileSafari) {
        this.layerTransp.addClass('safari-mobile')
      }
      this.layerContentTransp = this.layerTransp.find('.eh-overlay--content-body');
      this.layerCloseTransp = this.layerTransp.find('.eh-overlay--close');
      this.layerTitleTransp = this.layerTransp.find('.eh-overlay--headline');

      // TW 
      this.layerTw = $('.eh-marker-overlay-tw');
      if (this.isMobileSafari) {
        this.layerTw.addClass('safari-mobile');
      }
      this.layerContentTw = this.layerTw.find(
        '.eh-overlay--content-body'
      );
      this.layerCloseTw = this.layerTw.find('.eh-overlay--close');
      this.layerTitleTw = this.layerTw.find('.eh-overlay--headline');

      $('.js-ol-close', this.layerTransp).on('click', (ev) => {
        if ($(ev.target).selfOrClosest('.eh-overlay--content-body, .eh-overlay--close').length === 1) {
          return;
        }
        ev.preventDefault();
        this.closeLayerTransp();
      });
    }

    onMessage(message : Message) {
      if(message.params['$$legacy'] !== undefined) {
        return; // not supported
      }
      if(message.method === '*:open-url') {
        const title = message.params['title'];
        const ref = message.params['url'];
        if(ref) {
          this.showContent(ref, title, 'iframe');  // oder iframe-scaled
        }
      }
      else if(message.method === '*:open-id') {
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContent(ref, title, 'local');  // oder iframe-scaled
        }
      }
      else if(message.method === '*:open-transp-id') {
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContentTransp(ref, title, 'local-transp');  // oder iframe-scaled
        }
      }
      else if(message.method === '*:open-static2-id') { // FLEX
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContentTransp(ref, title, 'local-s2');  
        }
      }
      else if(message.method === '*:open-static2-wide-id') { // FlexDesc
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContentTransp(ref, title, 'local-s2w'); 
        }
      }
    }
  
    private registerOverlayLinks($base: JQuery<HTMLElement>) {
      $('[data-lightbox]:not([data-evict-lightbox=1])', $base).each((index, el)  => {
        const $link = $(el);
        let type : OverlayContentType = $(el).data('lightbox') || 'iframe';
        // FIXME: if feature on GEC is iframe-scaled and type is iframe ...:
        // type = 'iframe-scaled';
        $link.on('click', (event) => {
          event.preventDefault();
          if(!$link.hasClass('disabled')) {
            const href = $link.attr('href') || '#';
            const title = $link.data('title') || '';
            if(type === 'local-transp') {
              this.showContentTransp(href, title, type);
            } else if (type === 'local-tw') {
              this.showContentTw(href, title, type);
            } else {
                this.showContent(href, title, type);
            }
          }
        });
      });
      $('.js-overlay-close', $base).on('click', (ev) => {
        if ($(ev.target).selfOrClosest('.eh-overlay--content-body, .eh-overlay--close').length !== 1) {
          return;
        }
        ev.preventDefault();
        this.closeLayerTransp();
        this.closeLayerTw();
        this.closeLayer();
      });
    }

    private openLayer(forceInit: boolean = false) {
      this.closeLayerTransp(false);
      this.closeLayerTw(false);
      this.layer.show();
      if (this.getContentType() !== 'local' || forceInit) {
        Overlay.notifyReplaced($(this.layer));
      }
      this.layerClose.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayer();
      });
      eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
    }

    private closeLayer(deferred:boolean = true) {
      this.layer.hide();
      this.layerClose.off('click');
      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      
      const $el = this.layerContent.data(Overlay.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger(jQuery.Event(Overlay.EventIdPreClose));
      eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      if (deferred) {
        setTimeout(() => { // allow the Tracking to access the content before it is removed
          this.putBackLayer();
        }, 0);
      } else {
        this.putBackLayer();
      }
    }

    private putBackLayer() {
      if (['local'].indexOf(this.getContentType()) > -1) {
        const refId = this.layer.data(Overlay.DATA_KEY__LOCAL_REF_ID);
        if (refId) {
          const contentParent = $('#'+refId);
          if (contentParent.length === 1) {
            if(this.layerContent.find('.is-wrapper').length > 0) {
              contentParent.append(this.layerContent.find('.is-wrapper').children());
            } else {
              contentParent.append(this.layerContent.children());
            }
          }
        }
      } else {
          this.layerContent.empty();
      }
    }

    private openLayerTransp(forceInit: boolean = false) {
      this.closeLayer(false);
      this.closeLayerTw(false);
      this.layerTransp.show();
      if (this.getContentType() !== 'local-transp' || forceInit) {
        Overlay.notifyReplaced($(this.layerTransp));
      }
      this.layerCloseTransp.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTransp();
      });
      eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
    }

    private closeLayerTransp(deferred = true) {
      if(this.layerTransp.find('.eh-scroll-vertical').length === 1) {
        this.layerTransp.find('.eh-scroll-vertical').scrollTop(0);
      }
      if (this.layerTransp.find('.eh-overlay--content-body').length === 1) {
        this.layerTransp.find('.eh-overlay--content-body').scrollTop(0);
      }
      this.layerTransp.hide();
      this.layerCloseTransp.off('click');
      if (this.contentCloserTransp) {
        this.contentCloserTransp.off('click');
      }
      const $el = this.layerContentTransp.data(Overlay.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger(jQuery.Event(Overlay.EventIdPreClose));
      eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      if(deferred) {
        setTimeout(() => { // allow the Tracking to access the content before it is removed
          this.putBackLayerTransp();
        }, 0);
      } else {
        this.putBackLayerTransp();
      }
    }

    private putBackLayerTransp() {
      if (['local-transp', 'local-s2', 'local-s2w'].indexOf(this.getContentType()) > -1) {
        const refId = this.layerTransp.data(Overlay.DATA_KEY__LOCAL_REF_ID);
        if (refId) {
          const $originalParent = $('#' + refId);
          if ($originalParent.length === 1) {
            const $contentParentWrapperToTransferBack = this.layerContentTransp.find('.is-wrapper');
            const $contentParentToTransferBack = $contentParentWrapperToTransferBack.length > 0 ? $contentParentWrapperToTransferBack : this.layerContentTransp;
            $originalParent.append($contentParentToTransferBack.children());
          }
          else {
            Overlay.logger.debug('no single $originalParent found to put back', $originalParent);
          }
        }
      } else {
          this.layerContentTransp.empty();
      }
    }

    private openLayerTw(forceInit: boolean = false) {
      this.closeLayer(false);
      this.closeLayerTransp(false);
      this.layerTw.show();
      if (this.getContentType() !== 'local-tw' || forceInit) {
        Overlay.notifyReplaced($(this.layerTw));
      }
      this.layerCloseTw.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTw();
      });
      ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
    }
  
    private closeLayerTw(deferred = true) {
      if (this.layerTw.find('.eh-scroll-vertical').length === 1) {
        this.layerTw.find('.eh-scroll-vertical').scrollTop(0);
      }
      if (this.layerTw.find('.eh-overlay--content-body').length === 1) {
        this.layerTw.find('.eh-overlay--content-body').scrollTop(0);
      }
      this.layerTw.hide();
      this.layerCloseTw.off('click');
      if (this.contentCloserTw) {
        this.contentCloserTw.off('click');
      }
      const $el = this.layerContentTw.data(Overlay.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger($.Event(Overlay.EventIdPreClose));
      ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      if (deferred) {
        setTimeout(() => {
          // allow the Tracking to access the content before it is removed
          this.putBackLayerTw();
        }, 0);
      } else {
        this.putBackLayerTw();
      }
    }
  
    private putBackLayerTw() {
      if (['local-tw'].indexOf(this.getContentType()) > -1) {
        const refId = this.layerTw.data(Overlay.DATA_KEY__LOCAL_REF_ID);
        if (refId) {
          const $originalParent = $('#' + refId);
          if ($originalParent.length === 1) {
            const $contentParentWrapperToTransferBack = this.layerContentTw.find('.is-wrapper');
            const $contentParentToTransferBack = $contentParentWrapperToTransferBack.length > 0
                ? $contentParentWrapperToTransferBack
                : this.layerContentTw;
            $originalParent.append($contentParentToTransferBack.children());
          } else {
            Overlay.logger.debug('no single $originalParent found to put back (tw)', $originalParent);
          }
        } else {
          this.layerContentTw.empty();
        }
      }
    }

    /* dumb show */
    public putContent(content: JQuery<HTMLElement>, title?: string) {
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);

      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      this.layerContent.empty();
      
      this.layerContent.data(Overlay.DATA_KEY__OVERLAY_CONTENT, content);
      this.layerContent.append(eh.Overlay.createContentWrapper().append(content));
      this.layerTitle.empty();
      if (title) {
        this.layerTitle.html(title);
      }
      this.setContentType('raw');
      this.contentCloser = $('.js-overlay-close', this.layerContent);
      this.contentCloser.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayer();
      });
      
      this.openLayer();
    }

    private showContent(href: string, title: string, type: OverlayContentType) {
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);

      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      this.layerContent.empty();
      
      const innerContent = this.getTemplate(type, href);
      this.layerContent.data(Overlay.DATA_KEY__OVERLAY_CONTENT, innerContent.children());
      this.layerContent.append(innerContent);
      this.setContentType(type);
      this.layerTitle.empty();
      this.layerTitle.html(title);
      this.contentCloser = $('.js-overlay-close', this.layerContent);
      this.contentCloser.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayer();
      });
      this.openLayer();
    }

    private showContentTransp(href: string, title: string, type: OverlayContentType) {
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);

      if (this.layerContentTransp.closest('.eh-scroll-vertical').length === 1) {
        this.layerContentTransp.closest('.eh-scroll-vertical')[0].scrollTop = 0;
      }
      if (this.contentCloserTransp) {
        this.contentCloserTransp.off('click');
      }
      this.layerContentTransp.empty();
      
      const innerContent = this.getTemplate(type, href).children();
      this.layerContentTransp.data(Overlay.DATA_KEY__OVERLAY_CONTENT, innerContent);
      this.layerContentTransp.append(innerContent);
      this.setContentType(type);
      this.layerTitleTransp.empty();
      this.layerTitleTransp.html(title);
      this.contentCloserTransp = $('.js-overlay-close', this.layerContentTransp);
      this.contentCloserTransp.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTransp(true);
      });
      this.openLayerTransp();
    }

    private showContentTw(href: string, title: string, type: OverlayContentType) {
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);
  
      if (this.contentCloserTw) {
        this.contentCloserTw.off('click');
      }
      this.layerContentTw.empty();
  
      const innerContent = this.getTemplate(type, href);
      this.layerContentTw.data(
        Overlay.DATA_KEY__OVERLAY_CONTENT,
        innerContent.children()
      );
      this.layerContentTw.append(innerContent);
      this.setContentType(type);
      this.layerTitleTw.empty();
      this.layerTitleTw.html(title);
      this.contentCloserTw = $('.js-overlay-close', this.layerContentTw);
      console.log( this.contentCloserTw, this.layerContentTw);
      this.contentCloserTw.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTw();
      });
      this.openLayerTw();
    }

    private getTemplate(type: OverlayContentType, ref: string) : JQuery<HTMLElement> {
      switch (type) {
        case 'video': {
          return $(`<div class="eh-player"><video class="eh-image eh-layout--display-block eh-full-width" autoplay><source type="video/mp4" src="${ref}"/></video></div>`);
        }
        case 'iframe-fullw':
        case 'iframe': {
          return $(`<iframe class="eh-overlay--content-frame eh-no-b eh-full-width eh-full-height" src="${ref}" allow="fullscreen; clipboard-write;"></iframe>`);
        }
        case 'iframe-scaled': {
          return $(`<iframe class="eh-overlay--content-frame eh-no-b eh-full-width eh-full-height eh-iframe-scaled eh-iframe-scale-height" src="${ref}" allow="fullscreen; clipboard-write;" data-original-width="1024"></iframe>`);
        }
        case 'local': {
          let elId = ref;
          if (ref.indexOf('#') === 0) {
            elId = ref.substring(1);
          }
          this.layer.data(Overlay.DATA_KEY__LOCAL_REF_ID, elId);
          const wrapper = eh.Overlay.createContentWrapper();
          return wrapper.append($('#' + elId).children());
        }
        case 'local-s2':
        case 'local-s2w':
        case 'local-transp': {
          let elId = ref;
          if (ref.indexOf('#') === 0) {
            elId = ref.substring(1);
          }
          this.layerTransp.data(Overlay.DATA_KEY__LOCAL_REF_ID, elId);
          const wrapper = eh.Overlay.createCenterWrapper();
          return wrapper.append($('#' + elId).children());
        }
        case 'local-tw': {
          let elId = ref;
          if (ref.indexOf('#') === 0) {
            elId = ref.substring(1);
          }
          this.layerTw.data(Overlay.DATA_KEY__LOCAL_REF_ID, elId);
          const wrapper = eh.Overlay.createCenterWrapper();
          console.log("elId ", elId);
          return wrapper.append($('#' + elId).children());
        }
        default:
        return $('');
      }
    }

    private getContentType(): OverlayContentType {
      return this.layer.data('contentType');
    }

    private setContentType(type: OverlayContentType) {
      switch (type) {
        case 'iframe-fullw': {
          // iframe-fullw
          let box = $('.eh-center-page--large', this.layer);
          box.removeClass('eh-center-page--large').addClass('eh-center-page--large-off');
          break;
        }
        case 'local-s2w':
          // local-s2* white bg
          let box = $('.eh-overlay--content.eh-block-theme--10', this.layerTransp);
          box.removeClass('eh-block-theme--10').addClass('eh-block-theme--14');
          // local-s2w wide
          box = $('.eh-lightbox--static', this.layerTransp);
          box.removeClass('eh-lightbox--static').addClass('eh-lightbox--static-2').addClass("has-indent");
          break;
      case 'local-s2': {
          // local-s2* white bg
          let box = $('.eh-overlay--content.eh-block-theme--10', this.layerTransp);
          box.removeClass('eh-block-theme--10').addClass('eh-block-theme--14');
          // reset 
          box = $('.eh-lightbox--static-2', this.layerTransp);
          box.removeClass('eh-lightbox--static-2').removeClass('has-indent').addClass('eh-lightbox--static');
          break;
        }
        default: {
          // reset all
          let box = $('.eh-center-page--large-off', this.layer);
          box.removeClass('eh-center-page--large-off').addClass('eh-center-page--large');
          box = $('.eh-overlay--content.eh-block-theme--14', this.layerTransp);
          box.removeClass('eh-block-theme--14').addClass('eh-block-theme--10');
          box = $('.eh-lightbox--static-2', this.layerTransp);
          box.removeClass('eh-lightbox--static-2').removeClass('has-indent').addClass('eh-lightbox--static');
        }
      }
      return this.layer.data('contentType', type);
    }

    private static notifyReplaced(el: JQuery<HTMLElement>) {
      const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
      event.replacedTarget = el;
      $(':root').trigger(event);
    }

    private static createContentWrapper() {
      return $('<div class="eh-layout--abs-expand is-wrapper"></div>');
    }
    private static createCenterWrapper() {
      return $('<div class="is-wrapper"></div>');
    }
  }
  
}
